@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  background-color: #ffffff !important;
}

.services-bg {
  /* background: url("/public/images/services-bg.png"); */
  background: url("/public/images/services-bg.svg");
  background-size: cover;
}
